import { Text, View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import InnerSection from 'pdf/components/InnerSection';
import Section from 'pdf/components/Section';
import { Heading2, Heading3 } from 'pdf/components/typography';
import React from 'react';
import { ContactDetails } from 'components/CheckDetails/YourDriversSection/PolicyHolder/validation';
import { ExpandableTableData } from 'components/PolicyDetails/types';
import { Quote, Renewal } from 'state/quote/quote';
import { CsCheckYourDetailsPdf, CsPolicyDetailsPdf } from '../../types';
import Fields from '../Fields';

type YourDetailsProps = {
  theme: Theme;
  quote: Quote | Renewal;
  contactDetails: ContactDetails;
  csCheckYourDetails: CsCheckYourDetailsPdf;
  csPolicyDetails: CsPolicyDetailsPdf;
  mainDriverSuffix: string;
  carDetails: ExpandableTableData;
  policyholderDetails: ExpandableTableData;
  additionalDriversDetails: ExpandableTableData[];
};

const YourDetails: React.FC<YourDetailsProps> = ({
  theme,
  quote,
  contactDetails,
  csCheckYourDetails,
  csPolicyDetails,
  mainDriverSuffix,
  carDetails,
  policyholderDetails,
  additionalDriversDetails,
}) => (
  <>
    <Section>
      <Heading2>{csCheckYourDetails.your_car.heading}</Heading2>
      <InnerSection>
        {quote.vehicle.registration && <Text>{quote.vehicle.registration}</Text>}
        <Text style={{ color: theme.colors.neutral02 }}>
          {quote.vehicle.vehicleInfo?.make} {quote.vehicle.vehicleInfo?.model}
        </Text>
        <Text style={{ color: theme.colors.neutral02 }}>
          {quote.vehicle.vehicleInfo?.engineSize} cc -{' '}
          {quote.vehicle.vehicleInfo?.transmissionType} -{' '}
          {quote.vehicle.vehicleInfo?.fuelType}
        </Text>
      </InnerSection>
      <Fields {...carDetails} />
    </Section>
    <Section>
      <Heading2>{csCheckYourDetails.your_drivers.heading}</Heading2>
      <Heading3>
        {quote.policyHolder.firstName} {quote.policyHolder.lastName}{' '}
        {quote.policyHolder.isMainDriver ? mainDriverSuffix : ''}
      </Heading3>
      {/* These fields (email, phone) are handled separately because they are editable on the check details
      page, but we must still include them in the PDF. */}
      <Fields
        initialRows={[
          {
            title:
              csPolicyDetails.drivers_details.information_labels_policyholder
                .email_address,
            value: contactDetails.email,
          },
          {
            title:
              csPolicyDetails.drivers_details.information_labels_policyholder.telephone,
            value: contactDetails.mainTelephone,
          },
        ]}
      />
      <Fields {...policyholderDetails} />
      {additionalDriversDetails.map((additionalDriverData, index) => (
        <View style={{ marginTop: 16 }}>
          <Heading3>
            {quote.additionalDrivers[index].firstName}{' '}
            {quote.additionalDrivers[index].lastName}{' '}
            {quote.additionalDrivers[index].isMainDriver ? mainDriverSuffix : ''}
          </Heading3>
          <Fields {...additionalDriverData} />
        </View>
      ))}
    </Section>
  </>
);
export default YourDetails;
