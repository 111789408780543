import { Theme } from '@rsa-digital/evo-shared-components/theme';
import InnerSection from 'pdf/components/InnerSection';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading2 } from 'pdf/components/typography';
import React from 'react';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { FormatOptions, isRenewal, Quote, Renewal } from 'state/quote/quote';
import Question from '../../../components/Question';
import { CsCheckYourDetailsPdf } from '../../types';

type PreferencesProps = {
  quote: Quote | Renewal;
  theme: Theme;
  preferences: FullPreferenceDetails;
  csCheckYourDetails: CsCheckYourDetailsPdf;
};

const Preferences: React.FC<PreferencesProps> = ({
  quote,
  theme,
  preferences,
  csCheckYourDetails,
}) => {
  const getDocumentsAnswer = (): string | null => {
    if (preferences.useOtherFormats) {
      switch (preferences.otherFormat) {
        case FormatOptions.Paper:
          return csCheckYourDetails.documents.radio_button_text.paper;
        case FormatOptions.Braille:
          return csCheckYourDetails.documents.radio_button_text.braille;
        case FormatOptions.AudioCD:
          return csCheckYourDetails.documents.radio_button_text.audio_cd;
        case FormatOptions.Largeprint:
          return csCheckYourDetails.documents.radio_button_text.large_print;
        default:
          return null;
      }
    }
    return csCheckYourDetails.documents.radio_button_text.online;
  };

  const getKeepingYouInformedAnswer = (): string | null => {
    switch (preferences.shouldKeepInformed) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return null;
    }
  };

  return (
    <>
      <Section>
        <Heading2>{csCheckYourDetails.documents.heading}</Heading2>
        <RichText theme={theme} html={csCheckYourDetails.documents.description} />
        <InnerSection>
          <Question
            question={csCheckYourDetails.documents.format.question_text}
            answer={getDocumentsAnswer()}
          />
        </InnerSection>
      </Section>
      {!isRenewal(quote) && (
        <Section>
          <Heading2>{csCheckYourDetails.keeping_you_informed.heading}</Heading2>
          <RichText
            theme={theme}
            html={csCheckYourDetails.keeping_you_informed.description}
          />
          <RichText
            theme={theme}
            html={csCheckYourDetails.keeping_you_informed.extra_information}
          />
          <Question
            question={csCheckYourDetails.keeping_you_informed.question}
            answer={getKeepingYouInformedAnswer()}
          />
        </Section>
      )}
    </>
  );
};
export default Preferences;
