import { Text } from '@react-pdf/renderer';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isMtaPaymentRefund, isMtaSinglePaymentOnly } from 'api/businessLogic/mtaPayment';
import Fields from 'pdf/CheckYourDetailsPdf/components/Fields';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import {
  EmphasisParagraphText,
  Heading1,
  Heading2,
  Heading3,
  paragraphTextStyle,
} from 'pdf/components/typography';
import React from 'react';
import { formatInPoundsAndPenceWithSign } from 'helpers/poundsHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { CsMtaCheckDetailsPdf } from '../../MtaCheckYourDetailsPdf/types';

type MtaSummaryProps = {
  theme: Theme;
  mtaQuote: MtaQuote;
  csMtaCheckDetails: CsMtaCheckDetailsPdf;
};

const PriceSummaryHeader: React.FC<{ theme: Theme }> = ({ children, theme }) => (
  <Heading1 style={{ color: theme.colors.core01, marginBottom: -8 }}>{children}</Heading1>
);

const MtaSummary: React.FC<MtaSummaryProps> = ({
  theme,
  mtaQuote,
  csMtaCheckDetails: { summary_section, monthly_payment_info_banner },
}) => (
  <Section>
    {mtaQuote.mtaPaymentAmount === 0 && (
      <>
        <PriceSummaryHeader theme={theme}>
          {summary_section.no_cost_summary.heading}
        </PriceSummaryHeader>
        <Text style={[paragraphTextStyle, { marginBottom: 8 }]}>
          {summary_section.no_cost_summary.details}
        </Text>
      </>
    )}
    {mtaQuote.mtaPaymentAmount > 0 && (
      <>
        <Heading2>{summary_section.payment_summary.description}</Heading2>
        <PriceSummaryHeader theme={theme}>
          {formatInPoundsAndPenceWithSign(mtaQuote.mtaPaymentAmount)}
        </PriceSummaryHeader>
        <RichText theme={theme} html={summary_section.payment_summary.details} />
      </>
    )}
    {mtaQuote.mtaPaymentAmount < 0 && (
      <>
        <Heading2>{summary_section.refund_summary.description_above}</Heading2>
        <PriceSummaryHeader theme={theme}>
          {formatInPoundsAndPenceWithSign(-1 * mtaQuote.mtaPaymentAmount)}
        </PriceSummaryHeader>
        <EmphasisParagraphText>
          {summary_section.refund_summary.description_below}
        </EmphasisParagraphText>
        <RichText
          theme={theme}
          html={summary_section.refund_summary.details}
          style={{ marginTop: 8 }}
        />
      </>
    )}
    <Fields
      omitColon
      initialRows={[
        {
          title: summary_section.start_date_label,
          value: formatLongDateWithDayFirst(mtaQuote.mtaEffectiveFromDate),
        },
        {
          title: summary_section.policy_end_label,
          value: formatLongDateWithDayFirst(mtaQuote.expirationDate),
        },
      ]}
    />
    {!isMtaSinglePaymentOnly(mtaQuote) &&
      !isMtaPaymentRefund(mtaQuote) &&
      mtaQuote.mtaPaymentAmount !== 0 && (
        <>
          <Heading3 style={{ marginTop: 16 }}>
            {monthly_payment_info_banner.heading}
          </Heading3>
          <RichText theme={theme} html={monthly_payment_info_banner.body} />
        </>
      )}
  </Section>
);

export default MtaSummary;
