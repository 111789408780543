import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import { partition } from 'lodash';
import { CsTieredCheckYourDetailsPdf } from 'pdf/CheckYourDetailsPdf/TieredCheckYourDetailsPdf/types';
import Section from 'pdf/components/Section';
import { Heading2, Heading3 } from 'pdf/components/typography';
import React, { ReactElement } from 'react';
import { getBreakdownHeadingAndSummary } from 'components/AddOnSummaryPanel/BreakdownSummaryPanel/helpers';
import { getBreakdownPriceForExtraTier } from 'helpers/breakdownPriceHelper';
import replaceAddOnCostPlaceholders from 'helpers/replaceAddonCostPlaceholder';
import {
  BreakdownCoverLevel,
  getCurrentTier,
  LegalCoverageType,
  Quote,
  Renewal,
  TierInfo,
  TierOptions,
} from 'state/quote/quote';
import {
  CsAddOnBreakdownCoverPdf,
  CsAddOnPdf,
  CsAddOnsConfigPdf,
  CsCheckYourDetailsPdf,
  CsPolicyDetailsPdf,
} from '../../types';
import AddOn from '../AddOn';

type YourAddOnsProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csCheckYourDetails: CsCheckYourDetailsPdf;
  csTieredCheckYourDetails?: CsTieredCheckYourDetailsPdf;
  csPolicyDetails: CsPolicyDetailsPdf;
  csAddOnsConfig: CsAddOnsConfigPdf;
  csAddOnBreakdownCover: CsAddOnBreakdownCoverPdf;
  csAddOns: CsAddOnPdf[];
};

const YourAddOns: React.FC<YourAddOnsProps> = ({
  theme,
  quote,
  csCheckYourDetails,
  csTieredCheckYourDetails,
  csPolicyDetails,
  csAddOnsConfig,
  csAddOnBreakdownCover,
  csAddOns,
}) => {
  const [selectedAddOns, declinedAddOns] = partition(
    quote.coverages,
    (item) => item.isSelected
  );

  const selectedBreakdown =
    quote.breakdown.selectedBreakdown.isSelected &&
    quote.breakdown.covers.find(
      (cover) => cover.type === quote.breakdown.selectedBreakdown.coverLevel
    );

  const renderAddOnCard = (
    type: string,
    included: boolean,
    tierInfo: TierInfo | undefined,
    monthlyInstallmentPremium: number,
    annualPremium: number
  ): ReactElement => {
    const info = csAddOns.find((addOn) => addOn.type === type);
    if (!info) {
      throw new Error(`Add on content missing for ${type}`);
    }

    const priceText = isAnnualPayment(quote)
      ? replaceAddOnCostPlaceholders(csAddOnsConfig.yearly_subheading, annualPremium)
      : replaceAddOnCostPlaceholders(
          csAddOnsConfig.monthly_subheading,
          monthlyInstallmentPremium
        );

    const includedInExtra =
      tierInfo?.name === TierOptions.Extra && type === LegalCoverageType;

    return (
      <AddOn
        key={type}
        theme={theme}
        included={included}
        heading={info.heading}
        subheading={
          includedInExtra
            ? csTieredCheckYourDetails?.your_optional_extras.included_in_extra_label || ''
            : priceText
        }
        description={info.post_selection_summary}
      />
    );
  };

  const getBreakdownPriceText = (
    type: BreakdownCoverLevel | null,
    tierInfo: TierInfo | undefined,
    price: number
  ): string => {
    const additionalBreakdownPriceOnExtra =
      quote.breakdown.selectedBreakdown.coverLevel &&
      getBreakdownPriceForExtraTier(
        quote.breakdown.selectedBreakdown.coverLevel,
        isAnnualPayment(quote),
        quote.breakdown.covers
      );

    const paymentPeriodSubheadingOnExtra = isAnnualPayment(quote)
      ? csTieredCheckYourDetails?.your_optional_extras
          .additional_price_on_extra_annual_subheading
      : csTieredCheckYourDetails?.your_optional_extras
          .additional_price_on_extra_monthly_subheading;

    const paymentPeriodSubheading = isAnnualPayment(quote)
      ? csAddOnsConfig.yearly_subheading
      : csAddOnsConfig.monthly_subheading;

    const isExtraTierSelected = tierInfo?.name === TierOptions.Extra;

    if (isExtraTierSelected && type === BreakdownCoverLevel.Roadside) {
      return csTieredCheckYourDetails?.your_optional_extras.included_in_extra_label || '';
    }
    if (isExtraTierSelected && additionalBreakdownPriceOnExtra) {
      return replaceAddOnCostPlaceholders(
        paymentPeriodSubheadingOnExtra || '',
        additionalBreakdownPriceOnExtra
      );
    }
    return replaceAddOnCostPlaceholders(paymentPeriodSubheading, price);
  };

  const breakdownHeadingAndSummary = getBreakdownHeadingAndSummary(
    quote.breakdown.selectedBreakdown.isSelected
      ? quote.breakdown.selectedBreakdown.coverLevel
      : null,
    csAddOnBreakdownCover
  );
  return (
    <Section>
      <Heading2>{csCheckYourDetails.your_policy.optional_extras_heading}</Heading2>
      {(selectedAddOns.length || selectedBreakdown) && (
        <>
          <Heading3>{csPolicyDetails.optional_extras.included_title}</Heading3>
          {selectedAddOns.map(({ monthlyInstallmentPremium, annualPremium, type }) =>
            renderAddOnCard(
              type,
              true,
              getCurrentTier(quote),
              monthlyInstallmentPremium,
              annualPremium
            )
          )}
          {selectedBreakdown && (
            <AddOn
              theme={theme}
              included
              heading={breakdownHeadingAndSummary.heading}
              subheading={getBreakdownPriceText(
                quote.breakdown.selectedBreakdown.coverLevel,
                getCurrentTier(quote),
                isAnnualPayment(quote)
                  ? selectedBreakdown.annualPremium
                  : selectedBreakdown.monthlyInstallmentPremium
              )}
              description={breakdownHeadingAndSummary.summary}
            />
          )}
        </>
      )}
      {(declinedAddOns.length || !selectedBreakdown) && (
        <>
          <Heading3>{csPolicyDetails.optional_extras.not_included_title}</Heading3>
          {declinedAddOns.map(({ monthlyInstallmentPremium, annualPremium, type }) =>
            renderAddOnCard(
              type,
              false,
              getCurrentTier(quote),
              monthlyInstallmentPremium,
              annualPremium
            )
          )}
          {!selectedBreakdown && (
            <AddOn
              theme={theme}
              included={false}
              heading={breakdownHeadingAndSummary.heading}
              subheading={
                isAnnualPayment(quote)
                  ? replaceAddOnCostPlaceholders(
                      csAddOnBreakdownCover.yearly_subheading,
                      Math.min(
                        ...quote.breakdown.covers.map((cover) => cover.annualPremium)
                      )
                    )
                  : replaceAddOnCostPlaceholders(
                      csAddOnBreakdownCover.monthly_subheading,
                      Math.min(
                        ...quote.breakdown.covers.map(
                          (cover) => cover.monthlyInstallmentPremium
                        )
                      )
                    )
              }
              description={breakdownHeadingAndSummary.summary}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default YourAddOns;
