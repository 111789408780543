import { formatNumericDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isComprehensiveCover } from 'api/businessLogic/quote';
import { CsTieredCheckYourDetailsPdf } from 'pdf/CheckYourDetailsPdf/TieredCheckYourDetailsPdf/types';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { EmphasisParagraphText, Heading2 } from 'pdf/components/typography';
import React from 'react';
import {
  getCurrentTier,
  isRenewal,
  Quote,
  Renewal,
  TierOptions,
} from 'state/quote/quote';
import { CsCheckYourDetailsPdf, CsPolicyDetailsPdf } from '../../types';
import Fields from '../Fields';

type YourPolicyProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csCheckYourDetails: CsCheckYourDetailsPdf;
  csPolicyDetails: CsPolicyDetailsPdf;
  csTieredCheckYourDetails?: CsTieredCheckYourDetailsPdf;
};

const textForTier = (
  tier: TierOptions,
  content?: CsTieredCheckYourDetailsPdf
): string | undefined => {
  switch (tier) {
    case TierOptions.Essentials:
      return content?.your_cover.cover_labels.essentials;
    case TierOptions.Standard:
      return content?.your_cover.cover_labels.standard;
    case TierOptions.Extra:
      return content?.your_cover.cover_labels.extra;
    default:
      return undefined;
  }
};

const descriptionForTier = (
  tier: TierOptions,
  content?: CsTieredCheckYourDetailsPdf
): string | undefined => {
  switch (tier) {
    case TierOptions.Essentials:
      return content?.your_cover.essentials_description;
    case TierOptions.Standard:
      return content?.your_cover.standard_description;
    case TierOptions.Extra:
      return content?.your_cover.extra_description;
    default:
      return undefined;
  }
};

const YourPolicy: React.FC<YourPolicyProps> = ({
  theme,
  quote,
  csCheckYourDetails,
  csTieredCheckYourDetails,
  csPolicyDetails,
}) => {
  const quoteTier = getCurrentTier(quote);

  return (
    <Section>
      {quoteTier ? (
        <>
          <Heading2>{csTieredCheckYourDetails?.your_cover.heading}</Heading2>
          <EmphasisParagraphText>
            {textForTier(quoteTier.name, csTieredCheckYourDetails)}
          </EmphasisParagraphText>
          <RichText
            theme={theme}
            html={descriptionForTier(quoteTier.name, csTieredCheckYourDetails) || ''}
          />
        </>
      ) : (
        <>
          <Heading2>{csCheckYourDetails.your_policy.heading}</Heading2>
          {isComprehensiveCover(quote.policy.coverWanted) ? (
            <RichText theme={theme} html={csPolicyDetails.policy_details.comprehensive} />
          ) : (
            <RichText
              theme={theme}
              html={csPolicyDetails.policy_details.third_party_fire_and_theft}
            />
          )}
        </>
      )}
      <Fields
        omitColon
        initialRows={[
          {
            title: csPolicyDetails.policy_details.cover_start_date_label,
            value: formatNumericDate(
              isRenewal(quote) ? quote.renewalEffectiveFromDate : quote.effectiveFromDate
            ),
          },
          {
            title: csPolicyDetails.policy_details.cover_renewal_date_label,
            value: formatNumericDate(quote.renewalDate),
          },
        ]}
      />
    </Section>
  );
};

export default YourPolicy;
