import { Theme } from '@rsa-digital/evo-shared-components/theme';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Table, TableCell, TableRow } from 'pdf/components/Table';
import { Heading2, Heading3 } from 'pdf/components/typography';
import React, { ReactElement } from 'react';
import { ExcessData, ExcessTableData } from 'components/PolicyDetails/Excesses/data';
import { Quote, Renewal } from 'state/quote/quote';
import { CsCheckYourDetailsPdf, CsExcessesPdf } from '../../types';

type ExcessesProps = {
  theme: Theme;
  quote: Quote | Renewal;
  tableData: ExcessTableData | null;
  csCheckYourDetails: CsCheckYourDetailsPdf;
  csExcesses: CsExcessesPdf;
  fireAndTheftText: string;
};

const firstColWidth = '40%';
const colWidth = '20%';

const Excesses: React.FC<ExcessesProps> = ({
  theme,
  quote,
  tableData,
  csCheckYourDetails,
  csExcesses,
  fireAndTheftText,
}) => {
  if (quote.policy.coverWanted === 'ThirdPartyFireAndTheft') {
    return (
      <Section>
        <Heading2>{csCheckYourDetails.your_excess.heading}</Heading2>
        <RichText theme={theme} html={fireAndTheftText} />
      </Section>
    );
  }

  const renderHeaderRow = (): ReactElement => (
    <TableRow>
      <TableCell theme={theme} width={firstColWidth} />
      <TableCell theme={theme} width={colWidth}>
        {csExcesses.labels.compulsory_label}
      </TableCell>
      <TableCell theme={theme} width={colWidth}>
        {csExcesses.labels.voluntary_label}
      </TableCell>
      <TableCell
        theme={theme}
        width={colWidth}
        style={{ backgroundColor: theme.colors.neutral07 }}>
        {csExcesses.labels.total_label}
      </TableCell>
    </TableRow>
  );

  const renderDataRow = ({
    label,
    compulsory,
    voluntary,
    total,
  }: ExcessData): ReactElement => (
    <TableRow key={label}>
      <TableCell theme={theme} width={firstColWidth}>
        {label}
      </TableCell>
      <TableCell theme={theme} width={colWidth}>
        {compulsory}
      </TableCell>
      <TableCell theme={theme} width={colWidth}>
        {voluntary}
      </TableCell>
      <TableCell
        theme={theme}
        width={colWidth}
        style={{ backgroundColor: theme.colors.neutral07 }}>
        {total}
      </TableCell>
    </TableRow>
  );

  return (
    <Section>
      <Heading2>{csCheckYourDetails.your_excess.heading}</Heading2>
      <Heading3>{csExcesses.accidental_damage_heading}</Heading3>
      <Table theme={theme} style={{ marginBottom: 16 }}>
        {renderHeaderRow()}
        {tableData?.accidentalDamage.map(renderDataRow)}
      </Table>
      <Heading3>{csExcesses.other_excesses_heading}</Heading3>
      <Table theme={theme} style={{ marginBottom: 16 }}>
        {renderHeaderRow()}
        {tableData?.otherExcesses.map(renderDataRow)}
      </Table>
      <RichText theme={theme} html={csExcesses.additional_info} />
    </Section>
  );
};

export default Excesses;
