import { View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import React from 'react';
import { ThemeProps } from 'styled-components';

const Card: React.FC<ThemeProps<Theme>> = ({ theme, children }) => (
  <View
    wrap={false}
    style={{
      marginBottom: 8,
      width: 350,
      borderWidth: 1,
      padding: 16,
      borderColor: theme.colors.neutral04,
      borderStyle: 'solid',
      // we can't use the radius on the theme because react PDF doesn't support using a string
      // (e.g. '8px') here - I believe this is a bug in the renderer :(
      borderRadius: 8,
    }}>
    {children}
  </View>
);

export default Card;
