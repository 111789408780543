import { Text, View } from '@react-pdf/renderer';
import { EmphasisParagraphText } from 'pdf/components/typography';
import React from 'react';

type QuestionProps = {
  question: string;
  answer: string | null;
};

const Question: React.FC<QuestionProps> = ({ question, answer }) => (
  <View wrap={false} style={{ marginTop: 4 }}>
    <EmphasisParagraphText>{question}</EmphasisParagraphText>
    <Text>{answer ?? 'No selection made'}</Text>
  </View>
);

export default Question;
