import { Text } from '@react-pdf/renderer';
import { isAnnualPayment, isComprehensiveCover } from 'api/businessLogic/quote';
import PageTemplate from 'pdf/components/PageTemplate';
import Section from 'pdf/components/Section';
import { Heading1 } from 'pdf/components/typography';
import generatePdf from 'pdf/generatePdf';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ExcessTableData } from 'components/PolicyDetails/Excesses/data';
import { ExpandableTableData } from 'components/PolicyDetails/types';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { isRenewal, Quote, Renewal } from 'state/quote/quote';
import Declaration from './components/Declaration';
import Excesses from './components/Excesses';
import Preferences from './components/Preferences';
import YourAddOns from './components/YourAddOns';
import YourDetails from './components/YourDetails';
import YourPolicy from './components/YourPolicy';
import { CheckYourDetailsPdfContent, CsCheckYourDetailsPdf } from './types';

type CheckYourDetailsPdfProps = {
  quote: Quote | Renewal;
  preferences: FullPreferenceDetails;
  content: CheckYourDetailsPdfContent;
  carDetails: ExpandableTableData;
  policyholderDetails: ExpandableTableData;
  additionalDriversDetails: ExpandableTableData[];
  excessTableData: ExcessTableData | null;
  theme: DefaultTheme;
};

const getDeclarationMainSection = (
  quote: Quote | Renewal,
  content: CsCheckYourDetailsPdf
): string => {
  if (isRenewal(quote)) {
    if (isComprehensiveCover(quote.policy.coverWanted)) {
      return isAnnualPayment(quote)
        ? content.declaration.information.main_section_renewal_comprehensive
        : content.declaration.information.main_section_renewal_comprehensive_monthly;
    }
    return isAnnualPayment(quote)
      ? content.declaration.information.main_section_renewal_third_party_fire_theft
      : content.declaration.information
          .main_section_renewal_third_party_fire_theft_monthly;
  }

  if (isComprehensiveCover(quote.policy.coverWanted)) {
    return isAnnualPayment(quote)
      ? content.declaration.information.main_section_comprehensive
      : content.declaration.information.main_section_comprehensive_monthly;
  }
  return isAnnualPayment(quote)
    ? content.declaration.information.main_section_third_party_fire_theft
    : content.declaration.information.main_section_third_party_fire_theft_monthly;
};

const CheckYourDetailsPdf: React.FC<CheckYourDetailsPdfProps> = ({
  quote,
  preferences,
  content,
  carDetails,
  policyholderDetails,
  additionalDriversDetails,
  excessTableData,
  theme,
}) => {
  const {
    csCheckYourDetails,
    csPolicyDetails,
    allCsAddOn,
    csAddOnsConfig,
    csAddOnBreakdownCover,
    csExcesses,
    csPdfTemplate,
  } = replaceQuotePlaceholders(content, quote);

  const hero = isRenewal(quote)
    ? csCheckYourDetails.hero_renewal
    : csCheckYourDetails.hero;

  return (
    <PageTemplate logoSrc={csPdfTemplate.logo.localAsset.publicURL}>
      <Section>
        <Heading1>{hero.heading}</Heading1>
        {!!hero.subheading && <Text>{hero.subheading}</Text>}
      </Section>
      <YourDetails
        theme={theme}
        quote={quote}
        contactDetails={
          isRenewal(quote)
            ? { mainTelephone: quote.account.primaryPhone, email: quote.account.email }
            : preferences
        }
        csCheckYourDetails={csCheckYourDetails}
        csPolicyDetails={csPolicyDetails}
        mainDriverSuffix={csPolicyDetails.drivers_details.main_driver_suffix}
        carDetails={carDetails}
        policyholderDetails={policyholderDetails}
        additionalDriversDetails={additionalDriversDetails}
      />
      <YourPolicy
        theme={theme}
        quote={quote}
        csCheckYourDetails={csCheckYourDetails}
        csPolicyDetails={csPolicyDetails}
      />
      <YourAddOns
        theme={theme}
        quote={quote}
        csCheckYourDetails={csCheckYourDetails}
        csPolicyDetails={csPolicyDetails}
        csAddOnsConfig={csAddOnsConfig}
        csAddOnBreakdownCover={csAddOnBreakdownCover}
        csAddOns={allCsAddOn.nodes}
      />
      <Excesses
        theme={theme}
        quote={quote}
        tableData={excessTableData}
        csCheckYourDetails={csCheckYourDetails}
        csExcesses={csExcesses}
        fireAndTheftText={csPolicyDetails.excess.fire_and_theft_excess_text}
      />
      <Preferences
        theme={theme}
        quote={quote}
        preferences={preferences}
        csCheckYourDetails={csCheckYourDetails}
      />
      <Declaration
        theme={theme}
        quote={quote}
        declarationDetails={preferences}
        content={{
          heading: csCheckYourDetails.declaration.heading,
          endorsements: csCheckYourDetails.declaration.information.endorsements,
          mainSection: getDeclarationMainSection(quote, csCheckYourDetails),
          question: csCheckYourDetails.declaration.question.question_text,
        }}
      />
    </PageTemplate>
  );
};

export default CheckYourDetailsPdf;

export const generateCheckYourDetailsPdf = async (
  props: CheckYourDetailsPdfProps
): Promise<void> => generatePdf(<CheckYourDetailsPdf {...props} />, 'Quote');
