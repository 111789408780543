import { Text } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import Card from 'pdf/components/Card';
import RichText from 'pdf/components/RichText';
import { EmphasisParagraphText, SmallParagraphText } from 'pdf/components/typography';
import React from 'react';

type AddOnProps = {
  theme: Theme;
  included: boolean;
  heading: string;
  subheading: string;
  description: string;
};

const AddOn: React.FC<AddOnProps> = ({
  theme,
  included,
  heading,
  subheading,
  description,
}) => (
  <Card theme={theme}>
    <SmallParagraphText
      style={{
        color: included ? theme.colors.core01 : theme.colors.neutral02,
        marginBottom: 2,
      }}>
      {included ? 'Included' : 'Not included'}
    </SmallParagraphText>
    <EmphasisParagraphText style={{ marginBottom: 4 }}>{heading}</EmphasisParagraphText>
    <Text
      style={{
        color: included ? theme.colors.core01 : theme.colors.neutral02,
        marginBottom: 4,
      }}>
      {subheading}
    </Text>
    <RichText theme={theme} html={description} />
  </Card>
);

export default AddOn;
