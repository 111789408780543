/* PDF uses all static data and will not re-render - so using the index here is fine */
/* eslint-disable react/no-array-index-key */
import { Text } from '@react-pdf/renderer';
import { EmphasisParagraphText } from 'pdf/components/typography';
import React from 'react';
import { ExpandableTableData } from 'components/PolicyDetails/types';

const Fields: React.FC<ExpandableTableData & { omitColon?: boolean }> = ({
  initialRows,
  collapsibleRows,
  omitColon = false,
}) => (
  <>
    {initialRows.concat(collapsibleRows ?? []).map(({ title, value }, index) => (
      <Text key={index} style={{ marginTop: 4 }}>
        <EmphasisParagraphText>
          {title}
          {omitColon ? '' : ':'}
        </EmphasisParagraphText>{' '}
        {value}
      </Text>
    ))}
  </>
);

export default Fields;
