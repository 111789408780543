import { Text } from '@react-pdf/renderer';
import { isAnnualPayment } from 'api/businessLogic/quote';
import PageTemplate from 'pdf/components/PageTemplate';
import Section from 'pdf/components/Section';
import { Heading1 } from 'pdf/components/typography';
import generatePdf from 'pdf/generatePdf';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ExcessTableData } from 'components/PolicyDetails/Excesses/data';
import { ExpandableTableData } from 'components/PolicyDetails/types';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { isRenewal, Quote, Renewal } from 'state/quote/quote';
import { CsTieredCheckYourDetailsPdf, TieredCheckYourDetailsPdfContent } from './types';
import Declaration from '../components/Declaration';
import Excesses from '../components/Excesses';
import Preferences from '../components/Preferences';
import YourAddOns from '../components/YourAddOns';
import YourDetails from '../components/YourDetails';
import YourPolicy from '../components/YourPolicy';

type TieredCheckYourDetailsPdfProps = {
  quote: Quote | Renewal;
  preferences: FullPreferenceDetails;
  content: TieredCheckYourDetailsPdfContent;
  carDetails: ExpandableTableData;
  policyholderDetails: ExpandableTableData;
  additionalDriversDetails: ExpandableTableData[];
  excessTableData: ExcessTableData | null;
  theme: DefaultTheme;
};

const getDeclarationMainSection = (
  quote: Quote | Renewal,
  content: CsTieredCheckYourDetailsPdf
): string => {
  const isAnnual = isAnnualPayment(quote);

  if (isRenewal(quote)) {
    return isAnnual
      ? content.declaration.information.main_section_tiered_renewal
      : content.declaration.information.main_section_tiered_renewal_monthly;
  }
  return isAnnual
    ? content.declaration.information.main_section_tiered
    : content.declaration.information.main_section_tiered_monthly;
};

const TieredCheckYourDetailsPdf: React.FC<TieredCheckYourDetailsPdfProps> = ({
  quote,
  preferences,
  content,
  carDetails,
  policyholderDetails,
  additionalDriversDetails,
  excessTableData,
  theme,
}) => {
  const {
    csCheckYourDetails,
    csTieredCheckDetails,
    csPolicyDetails,
    allCsAddOn,
    csAddOnsConfig,
    csAddOnBreakdownCover,
    csExcesses,
    csPdfTemplate,
  } = replaceQuotePlaceholders(content, quote);

  const hero = isRenewal(quote)
    ? csCheckYourDetails.hero_renewal
    : csCheckYourDetails.hero;

  return (
    <PageTemplate logoSrc={csPdfTemplate.logo.localAsset.publicURL}>
      <Section>
        <Heading1>{hero.heading}</Heading1>
        {!!hero.subheading && <Text>{hero.subheading}</Text>}
      </Section>
      <YourPolicy
        theme={theme}
        quote={quote}
        csCheckYourDetails={csCheckYourDetails}
        csTieredCheckYourDetails={csTieredCheckDetails}
        csPolicyDetails={csPolicyDetails}
      />
      <YourAddOns
        theme={theme}
        quote={quote}
        csCheckYourDetails={csCheckYourDetails}
        csTieredCheckYourDetails={csTieredCheckDetails}
        csPolicyDetails={csPolicyDetails}
        csAddOnsConfig={csAddOnsConfig}
        csAddOnBreakdownCover={csAddOnBreakdownCover}
        csAddOns={allCsAddOn.nodes}
      />
      <YourDetails
        theme={theme}
        quote={quote}
        contactDetails={
          isRenewal(quote)
            ? { mainTelephone: quote.account.primaryPhone, email: quote.account.email }
            : preferences
        }
        csCheckYourDetails={csCheckYourDetails}
        csPolicyDetails={csPolicyDetails}
        mainDriverSuffix={csPolicyDetails.drivers_details.main_driver_suffix}
        carDetails={carDetails}
        policyholderDetails={policyholderDetails}
        additionalDriversDetails={additionalDriversDetails}
      />
      <Excesses
        theme={theme}
        quote={quote}
        tableData={excessTableData}
        csCheckYourDetails={csCheckYourDetails}
        csExcesses={csExcesses}
        fireAndTheftText={csPolicyDetails.excess.fire_and_theft_excess_text}
      />
      <Preferences
        theme={theme}
        quote={quote}
        preferences={preferences}
        csCheckYourDetails={csCheckYourDetails}
      />
      <Declaration
        theme={theme}
        quote={quote}
        declarationDetails={preferences}
        content={{
          heading: csCheckYourDetails.declaration.heading,
          endorsements: csCheckYourDetails.declaration.information.endorsements,
          mainSection: getDeclarationMainSection(quote, csTieredCheckDetails),
          question: csCheckYourDetails.declaration.question.question_text,
        }}
      />
    </PageTemplate>
  );
};

export default TieredCheckYourDetailsPdf;

export const generateTieredCheckYourDetailsPdf = async (
  props: TieredCheckYourDetailsPdfProps
): Promise<void> => generatePdf(<TieredCheckYourDetailsPdf {...props} />, 'Quote');
