import {
  addDaysToDate,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { isCurrentDateValidationDisabled } from 'helpers/backdatedPolicyHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { isAnnualPayment } from './quote';

export const isMtaSinglePaymentOnly = (mtaQuote: MtaQuote): boolean => {
  const noInstallmentsRemaining =
    mtaQuote.paymentDetails.monthlyPayment.installments?.dates?.length === 0;

  const is60DaysFromEndOfPolicy =
    !isCurrentDateValidationDisabled() &&
    addDaysToDate(localDateToUtcDate(new Date()), 60) >=
      new Date(mtaQuote.expirationDate);

  return isAnnualPayment(mtaQuote) || noInstallmentsRemaining || is60DaysFromEndOfPolicy;
};

export const isMtaPaymentRefund = (mtaQuote: MtaQuote): boolean => {
  if (mtaQuote.mtaPaymentAmount >= 0) {
    return false;
  }

  // We believe `monthlyPayment.total` is the total remaining cost of the policy after
  // the mta price change, when paying monthly. So we only do a one-off refund if it's negative
  const isRefundGreaterThanRemainingInstallments =
    (mtaQuote.paymentDetails.monthlyPayment.total ?? 0) < 0;

  return (
    isMtaSinglePaymentOnly(mtaQuote) ||
    (!isAnnualPayment(mtaQuote) && isRefundGreaterThanRemainingInstallments)
  );
};
