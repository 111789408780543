import { Text } from '@react-pdf/renderer';
import { isAnnualPayment } from 'api/businessLogic/quote';
import PageTemplate from 'pdf/components/PageTemplate';
import Section from 'pdf/components/Section';
import { Heading1 } from 'pdf/components/typography';
import generatePdf from 'pdf/generatePdf';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { DeclarationDetails } from 'components/Declaration';
import { ExcessTableData } from 'components/PolicyDetails/Excesses/data';
import { ExpandableTableData } from 'components/PolicyDetails/types';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import { isTieredQuote } from 'state/quote/quote';
import { TieredMtaCheckYourDetailsPdfContent } from './types';
import Declaration from '../../components/Declaration';
import Excesses from '../../components/Excesses';
import MtaSummary from '../../components/MtaSummary';
import YourAddOns from '../../components/YourAddOns';
import YourDetails from '../../components/YourDetails';
import YourPolicy from '../../components/YourPolicy';
import { CsMtaCheckDetailsPdf } from '../types';

type TieredMtaCheckYourDetailsPdfProps = {
  mtaQuote: MtaQuote;
  declarationDetails: DeclarationDetails;
  content: TieredMtaCheckYourDetailsPdfContent;
  carDetails: ExpandableTableData;
  policyholderDetails: ExpandableTableData;
  additionalDriversDetails: ExpandableTableData[];
  excessTableData: ExcessTableData | null;
  theme: DefaultTheme;
};

const getDeclarationMainSection = (
  mtaQuote: MtaQuote,
  content: CsMtaCheckDetailsPdf
): string => {
  if (isTieredQuote(mtaQuote)) {
    return isAnnualPayment(mtaQuote)
      ? content.declaration.main_information_tiered
      : content.declaration.main_information_tiered_monthly;
  }
  return isAnnualPayment(mtaQuote)
    ? content.declaration.main_information_comprehensive
    : content.declaration.main_information_comprehensive_monthly;
};

const TieredMtaCheckYourDetailsPdf: React.FC<TieredMtaCheckYourDetailsPdfProps> = ({
  mtaQuote,
  declarationDetails,
  content,
  carDetails,
  policyholderDetails,
  additionalDriversDetails,
  excessTableData,
  theme,
}) => {
  const {
    csMtaCheckDetails,
    csTieredCheckDetails,
    csCheckYourDetails,
    csPolicyDetails,
    allCsAddOn,
    csAddOnsConfig,
    csAddOnBreakdownCover,
    csExcesses,
    csPdfTemplate,
  } = replaceMtaQuotePlaceholders(content, mtaQuote);

  const { hero } = csMtaCheckDetails;

  return (
    <PageTemplate logoSrc={csPdfTemplate.logo.localAsset.publicURL}>
      <Section>
        <Heading1>{hero.heading}</Heading1>
        {!!hero.subheading && <Text>{hero.subheading}</Text>}
      </Section>
      <MtaSummary
        theme={theme}
        mtaQuote={mtaQuote}
        csMtaCheckDetails={csMtaCheckDetails}
      />
      <YourPolicy
        theme={theme}
        quote={mtaQuote}
        csCheckYourDetails={csCheckYourDetails}
        csTieredCheckYourDetails={csTieredCheckDetails}
        csPolicyDetails={csPolicyDetails}
      />
      <YourAddOns
        theme={theme}
        quote={mtaQuote}
        csCheckYourDetails={csCheckYourDetails}
        csTieredCheckYourDetails={csTieredCheckDetails}
        csPolicyDetails={csPolicyDetails}
        csAddOnsConfig={csAddOnsConfig}
        csAddOnBreakdownCover={csAddOnBreakdownCover}
        csAddOns={allCsAddOn.nodes}
      />
      <Excesses
        theme={theme}
        quote={mtaQuote}
        tableData={excessTableData}
        csCheckYourDetails={csCheckYourDetails}
        csExcesses={csExcesses}
        fireAndTheftText={csPolicyDetails.excess.fire_and_theft_excess_text}
      />
      <YourDetails
        theme={theme}
        quote={mtaQuote}
        contactDetails={{
          mainTelephone: mtaQuote.account.primaryPhone,
          email: mtaQuote.account.email,
        }}
        csCheckYourDetails={csCheckYourDetails}
        csPolicyDetails={csPolicyDetails}
        mainDriverSuffix={csPolicyDetails.drivers_details.main_driver_suffix}
        carDetails={carDetails}
        policyholderDetails={policyholderDetails}
        additionalDriversDetails={additionalDriversDetails}
      />
      <Declaration
        theme={theme}
        quote={mtaQuote}
        declarationDetails={declarationDetails}
        content={{
          heading: csCheckYourDetails.declaration.heading,
          endorsements: csCheckYourDetails.declaration.information.endorsements,
          mainSection: getDeclarationMainSection(mtaQuote, csMtaCheckDetails),
          question: csMtaCheckDetails.declaration.question.question_text,
        }}
      />
    </PageTemplate>
  );
};

export default TieredMtaCheckYourDetailsPdf;

export const generateTieredMtaCheckYourDetailsPdf = async (
  props: TieredMtaCheckYourDetailsPdfProps
): Promise<void> => generatePdf(<TieredMtaCheckYourDetailsPdf {...props} />, 'Quote');
