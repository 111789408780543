import { Theme } from '@rsa-digital/evo-shared-components/theme';
import BulletItem from 'pdf/components/BulletItem';
import Question from 'pdf/components/Question';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading2, Heading3 } from 'pdf/components/typography';
import React from 'react';
import { DeclarationDetails } from 'components/Declaration';
import { Quote, Renewal } from 'state/quote/quote';

type DeclarationProps = {
  theme: Theme;
  quote: Quote | Renewal;
  declarationDetails: DeclarationDetails;
  content: DeclarationContent;
};

type DeclarationContent = {
  heading: string;
  mainSection: string;
  endorsements: {
    heading: string;
    security_tracker: string;
    driving_other_cars: string;
  };
  question: string;
};

const Declaration: React.FC<DeclarationProps> = ({
  theme,
  quote,
  declarationDetails,
  content,
}) => {
  const hasEndorsements =
    quote.endorsements.hasDrivingOtherCars || quote.endorsements.hasSecurityTracker;

  const getDeclarationAnswer = (): string | null => {
    switch (declarationDetails.hasAgreedToDeclaration) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return null;
    }
  };

  return (
    <Section>
      <Heading2>{content.heading}</Heading2>
      <RichText theme={theme} html={content.mainSection} />
      {hasEndorsements && (
        <>
          <Heading3>{content.endorsements.heading}</Heading3>
          {quote.endorsements.hasSecurityTracker && (
            <BulletItem theme={theme}>{content.endorsements.security_tracker}</BulletItem>
          )}
          {quote.endorsements.hasDrivingOtherCars && (
            <BulletItem theme={theme}>
              {content.endorsements.driving_other_cars}
            </BulletItem>
          )}
        </>
      )}
      <Question question={content.question} answer={getDeclarationAnswer()} />
    </Section>
  );
};
export default Declaration;
