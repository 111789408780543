import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { formatInPoundsAndPence } from './poundsHelpers';
import replaceCsPlaceholders, { CsPlaceholders } from './replaceCsPlaceholders';
import { csQuotePlaceholders } from './replaceQuotePlaceholders';

const getMtaStartDate = (mtaQuote: MtaQuote): string =>
  formatLongDateWithDayFirst(mtaQuote.mtaEffectiveFromDate);

const csMtaQuotePlaceholders: CsPlaceholders<MtaQuote> = {
  ...csQuotePlaceholders,
  mtaStartDate: getMtaStartDate,
  refundAmount: (mtaQuote) => formatInPoundsAndPence(-mtaQuote.mtaPaymentAmount),
  paymentIncrease: (mtaQuote) => formatInPoundsAndPence(mtaQuote.mtaPaymentAmount),
  annualPaymentAmount: (mtaQuote) => formatInPoundsAndPence(mtaQuote.mtaPaymentAmount),
};

const replaceMtaQuotePlaceholders = <T>(csContent: T, mtaQuote: MtaQuote): T =>
  replaceCsPlaceholders(csContent, mtaQuote, csMtaQuotePlaceholders);

export default replaceMtaQuotePlaceholders;
